/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import { useRequester } from '../../hooks';
import { BaseResource, UserData, UserResource } from '../../resources';
import { useEffect, useMemo, useState } from 'react';
import { FileDownload } from '@mui/icons-material';
import { Menu, Table } from '../../design';

const today = new Date();
const weekAgo = new Date();
weekAgo.setDate(today.getDate() - 7);

function downloadAsCsv(columns: any[], rows: any[]) {
  let csv = 'data:text/csv;charset=utf-8,';
  for (const column of columns) csv += column.label + ',';

  csv += '\n';
  for (const row of rows) {
    for (const column of columns) csv += (row[column.id] || 0) + ',';
    csv += '\n';
  }

  const encodedUri = encodeURI(csv);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', 'activities.csv');

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

const makeColumns = (from: Date, to: Date) => {
  const columns = [
    { id: 'email', label: 'Email' },
    { id: 'access', label: 'Access' },
    { id: 'createdAt', label: 'Joined on' },
  ];
  for (let d = from; d < to; d.setDate(d.getDate() + 1)) {
    columns.push({
      id: d.toISOString().slice(0, 10),
      label: d.toISOString().slice(0, 10),
    });
  }
  return columns;
};

const makeRows = (from: Date, to: Date, users: UserData[], data: any[]) => {
  const dates = new Set<string>();
  for (let d = from; d < to; d.setDate(d.getDate() + 1)) {
    dates.add(d.toISOString().slice(0, 10));
  }

  const map = new Map<string, Map<string, string>>();
  const emails = new Set<string>();
  for (const entry of users) {
    emails.add(entry.email);
    map.set(
      entry.email,
      new Map<string, string>([
        ['createdAt', entry.createdAt],
        ['access', entry.access.join('/')],
      ])
    );
  }

  for (const entry of data) {
    if (!map.has(entry.email)) {
      map.set(entry.email, new Map<string, string>());
    }
    map.get(entry.email)?.set(entry.date, entry.metric);
  }

  const rows: any[] = [];

  for (const email of Array.from(emails).sort()) {
    const row: any = { id: email, email };
    row.createdAt = map.get(email)?.get('createdAt') || '';
    row.access = map.get(email)?.get('access') || '';
    for (const date of Array.from(dates)) {
      row[date] = map.get(email)?.get(date) || '';
    }
    rows.push(row);
  }

  return rows;
};

type Query = {
  offset: number;
  limit: number;
};

export default function ActivitiesPage() {
  const [hasReachedBottom, setHasReachedBottom] = useState(false);
  const [query, setQuery] = useState<Query>({ offset: 0, limit: 50 });

  const [downloading, setDownloading] = useState(false);
  const [activities, setActivities] = useState<any[]>([]);
  const [filter, setFilter] = useState<any>({
    from: weekAgo,
    to: today,
    type: 'app_usage',
  });

  const activitiesApi = useRequester<any>({
    onSubmit: (q: any) => BaseResource.http.get('/activities/all', q),
    onSuccess: setActivities,
    onError: console.log,
  });

  const usersApi = useRequester<UserResource[]>({
    onSubmit: (q) => UserResource.fetchMany(q),
    onSuccess: (data) => setHasReachedBottom(data.length < query.limit),
    onError: console.log,
  });

  useEffect(() => {
    usersApi.submit(query);
  }, [query.offset]);

  useEffect(() => {
    activitiesApi.submit({ groupBy: 'date', ...filter });
  }, [filter]);

  const columns = useMemo(
    () => makeColumns(new Date(filter.from), new Date(filter.to)),
    [filter]
  );

  const downloadData = async (guestOnly = false) => {
    setDownloading(true);
    const users = await UserResource.fetchAll({ guestOnly });

    const rows = makeRows(
      new Date(filter.from),
      new Date(filter.to),
      users,
      activities
    );
    downloadAsCsv(columns, rows);
    setDownloading(false);
  };

  const rows = makeRows(
    new Date(filter.from),
    new Date(filter.to),
    UserResource.list().map((u) => u.data),
    activities
  );

  return (
    <Box>
      <Box className="flex justify-between">
        <Box className="flex gap-4">
          <Typography variant="h5" fontWeight="bold">
            User Activities
          </Typography>
          {activitiesApi.loading && (
            <Box className="flex items-center">
              <CircularProgress size={20} />
            </Box>
          )}
        </Box>
        <Box className="flex gap-4">
          <TextField
            label="From"
            size="small"
            InputLabelProps={{ shrink: true }}
            type="date"
            defaultValue={weekAgo.toISOString().slice(0, 10)}
            onChange={(e) => {
              setFilter({ ...filter, from: new Date(e.target.value) });
            }}
          />
          <TextField
            label="To"
            size="small"
            InputLabelProps={{ shrink: true }}
            type="date"
            defaultValue={today.toISOString().slice(0, 10)}
            onChange={(e) => {
              setFilter({ ...filter, to: new Date(e.target.value) });
            }}
          />

          <Select
            size="small"
            defaultValue="app_usage"
            onChange={(e) => {
              setFilter({ ...filter, type: e.target.value });
            }}
          >
            <MenuItem value="app_usage">App Usage Time</MenuItem>
            <MenuItem value="lesson_count">Lesson Count</MenuItem>
            <MenuItem value="article_read">Article Read</MenuItem>
          </Select>

          <Menu
            anchor={
              <IconButton disabled={downloading}>
                {downloading ? (
                  <CircularProgress sx={{ ml: 0.5 }} size={20} />
                ) : (
                  <FileDownload />
                )}
              </IconButton>
            }
            items={[
              {
                name: 'all',
                label: 'All',
                onSelect: () => downloadData(false),
              },
              {
                name: 'guests',
                label: 'Guests Only',
                onSelect: () => downloadData(true),
              },
            ]}
          />
        </Box>
      </Box>
      <Box className="h-4" />

      <Table rows={rows} columns={columns} />

      {!hasReachedBottom && rows.length > 0 && (
        <Button
          disabled={usersApi.loading}
          sx={{ mt: 2 }}
          fullWidth
          onClick={() =>
            setQuery({ ...query, offset: query.offset + query.limit })
          }
        >
          Load More
        </Button>
      )}
    </Box>
  );
}
