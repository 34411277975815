import { useEffect } from 'react';
import { useRequester } from '../../hooks';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  Typography,
} from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { EikenMaterialData, EikenMaterialResource } from '../../resources';

interface EikenMaterialCardProps {
  material: EikenMaterialData;
}

function EikenMaterialCard({ material }: EikenMaterialCardProps) {
  const navigate = useNavigate();

  return (
    <>
      <Divider />
      <Box
        display="flex"
        gap={2}
        onClick={() => navigate(`/eiken-materials/${material.id}`)}
        className="cursor-pointer"
      >
        {material.image && (
          <Box flexShrink={0}>
            <img height={100} width={120} src={material.image} alt="" />
          </Box>
        )}
        <Box>
          <Typography variant="subtitle2">
            {material.referenceTextTitle}
          </Typography>
          <Typography variant="body2">{material.referenceText}</Typography>
        </Box>
      </Box>
    </>
  );
}

export default function EikenMaterialsPage() {
  const navigate = useNavigate();

  const materialsApi = useRequester<EikenMaterialResource[]>({
    onSubmit: () => EikenMaterialResource.fetchMany(),
    onError: console.log,
  });

  useEffect(() => {
    materialsApi.submit();
  }, []);

  const materials = EikenMaterialResource.list();

  return (
    <Box>
      <Box className="flex justify-between">
        <Box className="flex gap-4">
          <Typography variant="h5" fontWeight="bold">
            Eiken Materials
          </Typography>
          {materialsApi.loading && (
            <Box className="flex items-center">
              <CircularProgress size={20} />
            </Box>
          )}
        </Box>
        <Box className="flex gap-4">
          <Button
            variant="contained"
            endIcon={<AddIcon />}
            onClick={() => navigate(`/eiken-materials/new`)}
          >
            Add Material
          </Button>
        </Box>
      </Box>
      <Box className="h-4" />

      {materialsApi.error ? (
        <Alert severity="error">{materialsApi.error}</Alert>
      ) : (
        <Box className="flex flex-col gap-4">
          {materials.map((material) => (
            <EikenMaterialCard
              key={material.data.id}
              material={material.data}
            />
          ))}
        </Box>
      )}
    </Box>
  );
}
