/* eslint-disable react-hooks/exhaustive-deps */
import {
  Alert,
  Box,
  CircularProgress,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useConfirmation, useRequester } from '../../hooks';
import { useEffect } from 'react';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { EikenMaterialData, EikenMaterialResource } from '../../resources';

export default function EikenMaterialPage() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const deleteConfirmation = useConfirmation<EikenMaterialData>({
    getTitle: (material: EikenMaterialData) => 'Delete this material?',
    getDescription: (material: EikenMaterialData) =>
      'You are about to delete a material. This action cannot be undone.',
    onConfirm: async (material: EikenMaterialData) => {
      await EikenMaterialResource.deleteOne(material.id);
      navigate('/eiken-materials');
    },
  });

  const fetchMaterial = useRequester<EikenMaterialResource>({
    onSubmit: (id) => EikenMaterialResource.fetchOne(id),
    onError: console.log,
  });

  useEffect(() => {
    fetchMaterial.submit(id);
  }, [id]);

  if (!id) return <Alert severity="error">No material ID provided</Alert>;
  const material = EikenMaterialResource.get(id).data as EikenMaterialData;
  if (!material) {
    return (
      <Box className="flex flex-col items-center h-full justify-center">
        {fetchMaterial.error ? (
          <Alert severity="error">{fetchMaterial.error}</Alert>
        ) : (
          <CircularProgress />
        )}
      </Box>
    );
  }

  return (
    <Box className="flex flex-col gap-4">
      <Box className="flex flex-row gap-2">
        <Typography variant="h5" fontWeight="bold">
          {material.referenceTextTitle}
        </Typography>
        <IconButton
          size="small"
          color="primary"
          onClick={() => navigate(`/eiken-materials/${id}/edit`)}
        >
          <EditIcon fontSize="small" />
        </IconButton>
        <IconButton
          size="small"
          color="error"
          sx={{ ml: -1 }}
          onClick={() => deleteConfirmation.triggerFor(material)}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
      </Box>
      <Box className="flex flex-row gap-4">
        <Box flexShrink={0}>
          <img height={180} width={260} src={material.image} alt="" />
        </Box>
        <Typography
          variant="body2"
          color="text.secondary"
          whiteSpace="break-spaces"
        >
          {material.referenceText}
        </Typography>
      </Box>
      <Divider />
      <Typography variant="h6" fontWeight="bold">
        Content
      </Typography>
      <Box>
        <Typography variant="subtitle2" color="text.secondary">
          Pronunciation
        </Typography>
        <Typography variant="body1" whiteSpace="break-spaces">
          {material.content.pronunciation}
        </Typography>
      </Box>
      <Box>
        <Typography variant="subtitle2" color="text.secondary">
          Question 1
        </Typography>
        <Typography variant="body1" whiteSpace="break-spaces">
          {material.content.q1}
        </Typography>
      </Box>

      <Box>
        <Typography variant="subtitle2" color="text.secondary">
          Question 2
        </Typography>
        <Typography variant="body1" whiteSpace="break-spaces">
          {material.content.q2}
        </Typography>
      </Box>

      <Box>
        <Typography variant="subtitle2" color="text.secondary">
          Question 3
        </Typography>
        <Typography variant="body1" whiteSpace="break-spaces">
          {material.content.q3}
        </Typography>
      </Box>

      <Box>
        <Typography variant="subtitle2" color="text.secondary">
          Question 4
        </Typography>
        <Typography variant="body1" whiteSpace="break-spaces">
          {material.content.q4}
        </Typography>
      </Box>
      <Divider />
    </Box>
  );
}
