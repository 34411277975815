import { useEffect, useState } from 'react';
import { useConfirmation, useRequester } from '../../hooks';
import { QuestionData, QuestionResource } from '../../resources';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import QuestionFormModal from './QuestionFormModal';

interface QuestionCardProps {
  question: QuestionData;
  onDelete: () => void;
  onEdit: () => void;
}

function QuestionCard({ question, onDelete, onEdit }: QuestionCardProps) {
  return (
    <>
      <Divider />
      <Box display="flex" gap={2}>
        {question.image && (
          <Box flexShrink={0}>
            <img height={180} width={260} src={question.image} alt="" />
          </Box>
        )}
        <Box>
          <Typography variant="body2" color="text.secondary">
            Type{' '}
            <Box component="span" sx={{ fontWeight: 'bold', mr: 1 }}>
              {question.type}
            </Box>
            &bull;
            <IconButton size="small" color="primary" onClick={onEdit}>
              <EditIcon fontSize="small" />
            </IconButton>
            &bull;
            <IconButton size="small" color="error" onClick={onDelete}>
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Typography>
          <Typography variant="body1">{question.content}</Typography>
        </Box>
      </Box>
    </>
  );
}

export default function QuestionsPage() {
  const [showModal, setShowModal] = useState(false);
  const [questionForEdit, setQuestionForEdit] = useState<QuestionData>();
  const [type, setType] = useState<string>('-');

  const questionsApi = useRequester<QuestionResource[]>({
    onSubmit: () => QuestionResource.fetchMany(),
    onError: console.log,
  });

  useEffect(() => {
    questionsApi.submit();
  }, []);

  const deleteConfirmation = useConfirmation<QuestionData>({
    getTitle: (question) => 'Delete this question?',
    getDescription: (question) =>
      `You are about to delete a question. This action cannot be undone.`,
    onConfirm: async (question) => {
      await QuestionResource.deleteOne(question.id);
      questionsApi.submit();
    },
  });

  const questions = QuestionResource.list().filter(
    (q) => type === '-' || q.data.type === type
  );

  const onCloseModal = () => {
    setShowModal(false);
    setQuestionForEdit(undefined);
  };

  return (
    <Box>
      <Box className="flex justify-between">
        <Box className="flex gap-4">
          <Typography variant="h5" fontWeight="bold">
            Eiken Question Bank
          </Typography>
          {questionsApi.loading && (
            <Box className="flex items-center">
              <CircularProgress size={20} />
            </Box>
          )}
        </Box>
        <Box className="flex gap-4">
          <Select
            size="small"
            defaultValue="-"
            onChange={(e) => {
              setType(e.target.value);
            }}
          >
            <MenuItem value="-">All Types</MenuItem>
            <MenuItem value="1">Type 1</MenuItem>
            <MenuItem value="2">Type 2</MenuItem>
            <MenuItem value="3">Type 3</MenuItem>
            <MenuItem value="4">Type 4</MenuItem>
            <MenuItem value="pronunciation">Pronunciation</MenuItem>
          </Select>
          <Button
            variant="contained"
            endIcon={<AddIcon />}
            onClick={() => setShowModal(true)}
          >
            Add Question
          </Button>
        </Box>
      </Box>
      <Box className="h-4" />

      {questionsApi.error ? (
        <Alert severity="error">{questionsApi.error}</Alert>
      ) : (
        <Box className="flex flex-col gap-4">
          {questions.map((question) => (
            <QuestionCard
              key={question.data.id}
              question={question.data}
              onDelete={() => deleteConfirmation.triggerFor(question.data)}
              onEdit={() => {
                setQuestionForEdit(question.data);
                setShowModal(true);
              }}
            />
          ))}
        </Box>
      )}

      <QuestionFormModal
        question={questionForEdit}
        open={showModal}
        onClose={onCloseModal}
        onSuccess={() => {
          onCloseModal();
          questionsApi.submit();
        }}
      />
    </Box>
  );
}
