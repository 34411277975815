/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, Box, CircularProgress } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useRequester } from '../../hooks';
import { EikenMaterialData, EikenMaterialResource } from '../../resources';
import EikenMaterialForm from './EikenMaterialForm';

export default function EikenMaterialEditPage() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const fetchMaterial = useRequester<EikenMaterialResource>({
    onSubmit: (id) => EikenMaterialResource.fetchOne(id),
    onError: console.log,
  });

  const saveMaterial = useRequester<EikenMaterialResource>({
    onSubmit: (data: any) =>
      id
        ? EikenMaterialResource.updateOne(id, data)
        : EikenMaterialResource.createOne(data),
    onSuccess: (ob) => navigate(`/eiken-materials/${ob.data.id}`),
    onError: console.log,
  });

  useEffect(() => {
    id && fetchMaterial.submit(id);
  }, [id]);

  let material;
  if (id) material = EikenMaterialResource.get(id).data as EikenMaterialData;

  if (id && !material) {
    return (
      <Box className="flex flex-col items-center h-full justify-center">
        {fetchMaterial.error ? (
          <Alert severity="error">{fetchMaterial.error}</Alert>
        ) : (
          <CircularProgress />
        )}
      </Box>
    );
  }

  return (
    <Box>
      <EikenMaterialForm
        label={id ? 'Edit Eiken Material' : 'New Eiken Material'}
        onSubmit={saveMaterial.submit}
        data={material}
        saving={saveMaterial.loading}
        error={saveMaterial.error}
      />
    </Box>
  );
}
