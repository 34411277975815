import { useRef, useState } from 'react';
import {
  Alert,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  TextField,
  Typography,
} from '@mui/material';
import { SaveRounded as SaveIcon, Source } from '@mui/icons-material';
import { Form } from '../../design';
import { useForceRerender } from '../../hooks';
import { EikenMaterialData, EikenMaterialResource } from '../../resources';
import _ from 'lodash';

type Props = {
  onSubmit: (data: any) => void;
  saving?: boolean;
  data?: EikenMaterialData;
  error?: string;
  label: string;
};

export default function EikenMaterialForm({
  onSubmit,
  saving,
  data,
  error,
  label,
}: Props) {
  const forceRender = useForceRerender();
  const imageRef = useRef<HTMLInputElement>(null);
  const image = imageRef.current?.files?.[0];
  const [uploading, setUploading] = useState(false);

  const handleSubmit = async (data: any) => {
    setUploading(true);

    const image = imageRef.current?.files?.[0];
    if (image) data.image = await EikenMaterialResource.upload(image, 'image');

    onSubmit({
      ..._.pick(data, ['referenceTextTitle', 'referenceText', 'image']),
      content: _.pick(data, ['pronunciation', 'q1', 'q2', 'q3', 'q4']),
    });

    setUploading(false);
  };

  const busy = uploading || saving;

  return (
    <Form
      onSubmit={handleSubmit}
      className="flex flex-col gap-4"
      disabled={busy}
    >
      <Typography variant="h6" sx={{ lineHeight: 1 }}>
        {label}
      </Typography>
      <Box className="flex gap-4">
        <Box>
          <label className="hover:cursor-pointer">
            <Avatar
              sx={{
                height: 200,
                width: 200,
                border: '1px dotted',
                borderRadius: 1,
              }}
              variant="square"
              src={image ? URL.createObjectURL(image) : data?.image}
            >
              <Source />
            </Avatar>

            <input
              ref={imageRef}
              hidden
              type="file"
              accept="image/*"
              onChange={() => setTimeout(forceRender, 100)}
            />
          </label>
        </Box>
        <Box className="flex flex-grow flex-col gap-4">
          <TextField
            fullWidth
            name="referenceTextTitle"
            label="Reference Text Title"
            defaultValue={data?.referenceTextTitle}
          />

          <TextField
            fullWidth
            name="referenceText"
            label="Reference Text"
            defaultValue={data?.referenceText}
            multiline
          />
        </Box>
      </Box>
      <Divider />

      <Box className="grid grid-cols-2 gap-4">
        <TextField
          className="col-span-2"
          fullWidth
          name="pronunciation"
          label="Pronunciation"
          defaultValue={data?.content.pronunciation}
          multiline
        />

        <TextField
          fullWidth
          name="q1"
          label="Question 1"
          defaultValue={data?.content.q1}
          multiline
        />

        <TextField
          fullWidth
          name="q2"
          label="Question 2"
          defaultValue={data?.content.q2}
          multiline
        />
        <TextField
          fullWidth
          name="q3"
          label="Question 3"
          defaultValue={data?.content.q3}
          multiline
        />

        <TextField
          fullWidth
          name="q4"
          label="Question 4"
          defaultValue={data?.content.q4}
          multiline
        />
      </Box>

      <Divider />

      <Box className="flex flex-row gap-4">
        <Button
          size="large"
          startIcon={
            busy ? (
              <CircularProgress size={24} />
            ) : (
              <SaveIcon sx={{ height: 24, width: 24 }} />
            )
          }
          type="submit"
          variant="contained"
          disabled={busy}
        >
          Save Material
        </Button>
        {error && <Alert severity="error">{error}</Alert>}
      </Box>
    </Form>
  );
}
